import Swiper from 'swiper/swiper-bundle.min';
import anime from 'animejs';

export function index(){
	const ws = document.getElementById('worksider');
	if(ws){
		new Swiper(ws, {
			spaceBetween: 0,
			slidesPerView: 'auto',
			centeredSlides: false,
			loop: true,
			speed: 600,
			grabCursor: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
		});
	}
};